import * as Sentry from '@sentry/remix';
/**
 * By default, Remix will handle hydrating your app on the client for you.
 * You are free to delete this file if you'd like to, but if you ever want it revealed again, you can run `npx remix reveal` ✨
 * For more information, see https://remix.run/file-conventions/entry.client
 */
import { RemixBrowser, useLocation, useMatches } from '@remix-run/react';
import { startTransition, StrictMode, useEffect } from 'react';
import { hydrateRoot } from 'react-dom/client';
import { CacheAdapter, configureGlobalCache } from 'remix-client-cache';
import { baseQueryClient } from '~/lib/react-query/configuration';

Sentry.init({
  dsn: 'https://ea5928e250238ef4bd16e90b2088701a@o4508177296785408.ingest.de.sentry.io/4508177450598480',
  tracesSampleRate: 1,
  environment: process.env.NODE_ENV,
  enabled: process.env.NODE_ENV === 'production',
  integrations: [Sentry.browserTracingIntegration({
    useEffect,
    useLocation,
    useMatches
  })]
});

class ReactQueryAdapter implements CacheAdapter {
  async getItem(key: string) {
    return baseQueryClient.getQueryData(['route', key]);
  }

  async setItem(key: string, value: string) {
    return baseQueryClient.setQueryData(['route', key], value);
  }

  async removeItem(key: string) {
    return baseQueryClient.removeQueries({
      queryKey: [key]
    });
  }
}

configureGlobalCache(() => new ReactQueryAdapter());

startTransition(() => {
  hydrateRoot(
    document,
    <StrictMode>
      <RemixBrowser/>
    </StrictMode>
  );
});
